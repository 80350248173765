import logo from "./logo.svg";
import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS styles
// import { Link } from "react-router-dom";
import audio1 from "./Jaming.mp4";
import audio2 from "./Move.mp4";
import audio3 from "./Drown in the Clouds of Dew.mp4";
import audio4 from "./Close My Eyes.mp4";
import audio5 from "./Who loves the sun.mp4";
import audio6 from "./Fast Car Superstar.mp4";
import audio7 from "./Pump up the jam.mp4";
import audio8 from "./One more time.mp4";

//
import videoOne from "./VideoOne.mp4";
import videoTwo from "./VideoTwo.mp4";
import videoThree from "./VideoThree.mp4";
import videoFour from "./VideoFour.mp4";
import videoFive from "./VideoFive.mp4";
import videoSix from "./VidoeSix.mp4";
import videoSeven from "./VideoSeven.mp4";

import dex from "./dex.png";
import iconOne from "./brand-x.png";
import iconTwo from "./brand-instagram.png";
import iconThree from "./icon-three.png";

import play from "./play.svg";
import pause from "./pause.svg";
import skip from "./skip.svg";
import skipBack from "./skip-back.svg";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration (in milliseconds)
      once: false, // Whether animation should happen only once
      mirror: false, // Whether elements should animate out while scrolling past them
    });
  }, []);

  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  // const videoRef = useRef(null);

  const tracks = [
    audio1,
    audio2,
    audio3,
    audio4,
    audio5,
    audio6,
    audio7,
    audio8,
  ];
  const trackNames = [
    "Track 1: Jamming ",
    "Track 2: Move",
    "Track 3: Drown In The Clouds Of Dew",
    "Track 4: Close My Eyes",
    "Track 5: Who Loves The Sun",
    "Track 6: Fast Car Superstar",
    "Track 7: Pump up the jam",
    "Track 8: One More Time",
  ];

  // Play or pause the current audio and video

  // Play or pause the current audio
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Skip to the next track
  const skipForward = () => {
    setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
    setIsPlaying(true);
  };

  // Skip to the previous track
  const skipBackward = () => {
    setCurrentTrackIndex((prevIndex) =>
      prevIndex === 0 ? tracks.length - 1 : prevIndex - 1
    );
    setIsPlaying(true);
  };

  // Sync audio state when the audio ends
  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  // When the track changes, update the audio source
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
      if (isPlaying) {
        audioRef.current.play();
      }
    }
  }, [currentTrackIndex, isPlaying]);

  return (
    <div className="App">
      <h2 data-aos="zoom-in" className="header-text">
        JAM <span>CAT</span>
      </h2>

      <div className="section-1">
        <div className="section-1-main">
          <div className="video-container">
            <video autoPlay muted loop playsInline className="custom-video">
              <source src={videoTwo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className="playlist-container-main">
        <div className="playlist-container">
          <div className="playlist">
            <audio
              ref={audioRef}
              src={tracks[currentTrackIndex]}
              onEnded={handleAudioEnd}
            />
            <h1>{trackNames[currentTrackIndex]}</h1>
          </div>

          <div className="playlist-controls">
            <button onClick={skipBackward}>
              <img src={skipBack} className="skip-img" />
            </button>

            <button onClick={togglePlayPause}>
              <img
                src={isPlaying ? pause : play} // Change icon dynamically
                alt={isPlaying ? "Pause" : "Play"}
                className="play-img"
              />
            </button>

            <button onClick={skipForward}>
              <img src={skip} className="skip-img" />
            </button>
          </div>
        </div>

        <a className="text-1">My Favorite Song is now playing</a>
      </div>

      <div className="section-2">
        <h2 data-aos="zoom-in">About</h2>

        <a>
          Once rugged by its original creator, the Jam Cat faced uncertainty,
          left stranded in the void. But a collective of resilient Jammers rose
          to the occasion. United by purpose and fueled by determination, they
          ensured the cat kept jamming—left to right, endlessly, tirelessly, as
          a symbol of perseverance and community. Jam Cat now stands as a beacon
          of resilience, a reminder that no matter the setback, the jam never
          stops.
        </a>
      </div>

      <div className="section-3">
        <a href="https://x.com/jamcat_sol">
          <img src={iconOne} />
        </a>

        <a href="https://www.instagram.com/jamcatsolana/">
          <img src={iconTwo} />
        </a>

        <a href="https://t.me/JamCatCTOportal">
          <img src={iconThree} />
        </a>
      </div>

      <div className="section-4">
        <h2 data-aos="zoom-in">THE JAM CAT MEME</h2>

        <div className="section-4-main">
          <div className="video-container-2">
            <video autoPlay muted playsInline loop className="custom-video-2">
              <source src={videoOne} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="video-container-2">
            <video autoPlay muted playsInline loop className="custom-video-2">
              <source src={videoThree} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="video-container-2">
            <video autoPlay muted playsInline loop className="custom-video-2">
              <source src={videoFour} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="video-container-2">
            <video autoPlay muted playsInline loop className="custom-video-2">
              <source src={videoFive} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="video-container-2">
            <video autoPlay muted playsInline loop className="custom-video-2">
              <source src={videoSix} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <a
          href="https://dexscreener.com/solana/35jzmqqc6ewrw6pefwdlhmtxbkvnc9mxpbes4rbws1ww"
          className="buy-button"
        >
          <img src={dex} className="dex-logo" />
          <h1 className="buynow-text">Buy Now</h1>
        </a>
      </div>
    </div>
  );
}

export default App;
